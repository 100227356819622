import { userLogin, userRegister } from "../../api/user";
import { setToken, clearToken, setRefreshToken } from "../../utils/auth";

const useUserStore = {
    state: () => ({
        user: {}
    }),
    actions: {
        // 用户注册
        register(formData) {
            return new Promise((resolve, reject) => {
                userRegister(formData).then(res => {
                    setToken(res.data.accessToken)
                    setRefreshToken(res.data.refreshToken)
                    resolve()
                }).catch(error => {
                    clearToken()
                    reject(error)
                })
            })
        },
        // 用户登录
        login(formData) {
            return new Promise((resolve, reject) => {
                userLogin(formData).then(res => {
                    setToken(res.data.accessToken)
                    setRefreshToken(res.data.refreshToken)
                    resolve()
                }).catch(error => {
                    clearToken()
                    reject(error)
                })
            })
        }
        // 退出账号
    }
}

export default useUserStore
